@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);
body {
  margin: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.sr-only {
  display: none;
}

.nav-list .list {
  font-family: "Open Sans";
  color: "#1b1b1b";
}

.logo-sadeaf-header {
  margin: 20px 135px;
}

.link span:hover {
  color: #ee591f;
}

.container-header {
  /* padding-left: 120px; */
}

.publications:hover .container-submenu-news,
.container-submenu-news:hover {
  display: block;
}

.deaf-education:hover .container-submenu-services2,
.container-submenu-services2:hover {
  display: block;
}

.access-services:hover .container-submenu-sejrvices,
.container-submenu-services:hover {
  display: block;
}

.link:hover .container-submenu,
.container-submenu:hover {
  display: block;
}

.container-link {
  background-color: #f2951d !important;
  border-bottom: 1.5px solid #c64412;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.container-link:hover {
  background-color: #c64412 !important;
  transition: 0.3ms ease-out;
}

.link-submenu {
  color: #ffffff !important;
}

.icon {
  background-color: #d0d0d0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  border-radius: 100%;
  height: 30px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding: 0px;
  text-align: center;
  transition: background 0.2s ease-in-out 0s;
  width: 30px;
  margin-left: 4px;
}

.facebook:hover {
  background-color: #4c66a4;
}
.youtube:hover {
  background-color: #cc2423;
}

.icon-img {
  color: #ffffff;
}

/* Large Screen */
@media screen and (min-width: 480px) {
  .container-submenu {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 200px;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
  }
  .container-submenu-services {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 200px;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 141px;
  }

  .container-submenu-services2 {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 200px;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 240px;
  }

  .container-submenu-news {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 200px;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 200px;
  }

  .container-icon {
    float: right;
    margin-right: 50px;
    margin-bottom: 5px;
  }
}

/* Mobile Screen */
@media screen and (max-width: 425px) {
  .logo-sadeaf-header {
    margin: 20px auto;
    display: block;
  }
  
  .container-submenu {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
  }
  .container-submenu-services {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 141px;
  }

  .container-submenu-services2 {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 240px;
  }

  .container-submenu-news {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;

    top: 240px;
  }

  .container-icon {
    display: flex;
    justify-content: center;
    margin-right: 50px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 376px) {
  .container-submenu {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
  }
  .container-submenu-services {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 141px;
  }

  .container-submenu-services2 {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 240px;
  }

  .container-submenu-news {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;

    top: 240px;
  }
}

@media screen and (max-width: 361px) {
  .container-submenu {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
  }
  .container-submenu-services {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 141px;
  }

  .container-submenu-services2 {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 240px;
  }

  .container-submenu-news {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;

    top: 240px;
  }
}

.container-title-image {
  margin: 0;
}

.link-navbar {
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: #777;
  font-size: 14px;
  margin: 0 10px;
}

.container-navbar {
  padding: 20px 50px;
  background-color: #f8f8f8;
  width: 97%;
  margin: 0 auto;
  border-bottom: 1.2px solid #e7e7e7;
  display: flex;
}

.container-link-upload {
  display: flex;
  margin-left: 10px;
  background-color: unset !important;
  border-bottom: unset;
  min-height: unset;
  align-items: unset;
}

.container-link-upload:hover {
  background-color: unset !important;
}
.link-navbar {
  padding: 5px 50px;
}

.container-form-upload {
  width: 97%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 30px;
}

.container-form-upload h2 {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 36px;
}

.container-form-upload p {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px;
}

.info-upload {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 11px;
}

.submit-upload {
  font-size: 14px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #337ab7;
  border-color: #2e6da4;
  width: 70px;
  margin-top: 15px;
}

.submit-upload:hover {
  background-color: blue;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url(/static/media/HelveticaNeue-Roman.da8986e1.otf) format('opentype');
}

td {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9vw;
}

.total-record-container {
  margin: 10px 20px;
}

.container-donation-list {
  padding: 20px;
}
.container-donation-list h1 {
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 30px;
}

.info-download {
  color: #337ab7;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.icon-download {
  color: #337ab7;
}

.link-download:hover {
  color: rgb(48, 48, 177);
}

.upload-add-row-container {
  width: 100%;
  border: solid 1px #ccc;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.upload-add-form {
  margin: 0 5px;
  width: 20%;
  min-width: 9vw;
}

.react-bootstrap-table {
  overflow-x: auto;
  font-weight: 200 !important;
}

svg {
  height: 1rem !important;
  width: 1rem !important;
  margin-left: 1px;
  cursor: pointer;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue-Roman.da8986e1.otf) format("opentype");
}

.container-info-detail {
  padding: 0 19px 0 19px;
  margin-top: 5px;
}

.btn-cancel {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-left: 3px;
}

.btn-cancel-detail {
  background-color: #fff;
  border-color: #ccc;
  color: #333;
  width: 90px;
  height: 36px;
}

.btn-cancel-detail:hover {
  background-color: gray;
}

.container-content-detail {
  padding: 0 10vw;
}

.icon-cloud {
  margin: auto;
  height: 31px;
  width: 33px;
  margin: 0 auto;
}

.title-detail {
  font-size: 18px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #777777;
  background-color: #f5f5f5;
  padding: 10px;
}

.content-detail {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  margin: 0;
}

.content-detail {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333333;
  margin: 0;
}

